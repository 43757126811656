<template>
  <ul class="menu-nav">
    <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
<!--          <i class="menu-icon flaticon2-architecture-and-city"></i>-->
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="$store.state.auth.user.type == 1"
        to="/favourites"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
<!--          <i class="menu-icon flaticon2-architecture-and-city"></i>-->
          <span class="menu-text">My Favourites</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="$store.state.auth.user.type == 1"
        to="/payments"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Payments</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="$store.state.auth.user.type == 1"
        to="/submit-article"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Submit a Feature Article</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="$store.state.auth.user.type == 1"
        to="/preferences"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">My Preferences</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="$store.state.auth.user.type == 2"
        to="/arbitration"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Arbitration</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="$store.state.auth.user.type == 2"
        to="/mediation"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Mediation</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="$store.state.auth.user.type == 2"
        to="/bookings"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Bookings</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="$store.state.auth.user.type == 2"
        to="/client-payments"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Payments</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
